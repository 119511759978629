
/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  /*border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;*/
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}


.modal-90w {
	width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0 !important;
}



.expand {
    padding: 1.2rem;
    position: absolute;
    right: 30px;
    top: -2px;
    margin: 0;
    cursor: pointer;
  }