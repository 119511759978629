.event-red {
	background-color: red !important;
}

.event-green{
	background-color: green !important;
}

.event-azure{
	background-color: pink !important;
}

.event-orange{
	background-color: orange !important;
}


.rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 22px !important;
    text-align: center;
    width:100%;
}